footer {
  padding: 1rem 1rem 1rem 1rem !important;
  background-color: #363636 !important;
  color: #f5f5f5 !important;
  width: 100%;
}

footer .content * {
  margin: auto 10px auto 10px;
}
