#character-list {
  margin-top: 20px;
  background: #363636;
}

.title,
.subtitle {
  color: white !important;
}

.card {
  background: hsl(0, 0%, 14%);
  color: white !important;
}

.card a,
.card a:link,
.card a:visited {
  color: white !important;
}

.characters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.character-card {
  width: 300px;
  height: 150px;
  margin: 20px;
}

.learned {
  background: green !important;
}

.my-chars-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-chars-header * {
  margin: 20px;
}

.starred-header * {
  margin: 20px;
}

.content {
  display: flex;
}

.content .profession-holder,
.content .profession-holder-empty {
  margin: 5px;
}

.card {
  margin: 10px;
}

#character-list {
  margin-bottom: 3rem;
}

.needs-login {
  color: white;
  text-align: center;
  padding: 2rem;
}
