.profession-holder img {
  width: 60px;
  height: 60px;
  border: 2px solid gray;
}

.profession-holder-empty {
  width: 60px;
  height: 60px;
  border: 2px solid gray;
  color: white;
  text-align: center;
  vertical-align: center;
}
