.char-tools {
  margin: auto;
  width: 80%;

  display: flex;
  justify-content: flex-end;
}

.char-tools .button {
  margin-right: 5px;
}
