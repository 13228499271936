.recipe-list {
  width: 80%;
  margin: auto;

  overflow: auto;
  max-height: 75vh;
  color: white;
}

.recipe-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0 5px 0;
  background: black;
}

.zzz * {
  margin: 0 5px 0 5px;
}
