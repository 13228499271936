#hero-bg {
  background: url("./img/hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3px);
  transform: scale(1.03);
  position: absolute;
  width: 100%;
  height: 100%;
}

#hero {
  background: none !important;
  position: relative;
  overflow: hidden;
}

#home-body {
  color: white;
  font-size: 20px;
}

#home a,
#home a:link,
#home a:visited {
  text-decoration: underline;
}

#home a:hover {
  background: white;
  color: black;
}
