.item {
  color: white;
  background: black;
  width: 300px;
  text-align: center;
}

.learned .item {
  background: green;
}

.icon-only {
  width: 64px !important;
  display: inline-block;
}

.item a {
  display: flex;
  align-items: center;
}

.item a:hover span {
  color: yellow;
}

.item span {
  margin-left: 20px;
}

.item-icon {
  position: relative;
  height: 64px;
  width: 64px;
  border: 2px solid gray;
}

.item-icon img {
  height: 60px;
  width: 60px;
}

.amount {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 24px;
}

a {
  color: white;
  text-decoration: none;
}
