.character-view {
  margin-top: 60px;

  .character-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }

  .character-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .title {
    font-size: 64px;
    vertical-align: middle;
  }

  .chracter-professions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .chracter-professions div {
    margin: 0 5px 0 5px;
  }

  .profession-header {
    width: 80%;
    margin: auto;
    border-bottom: 2px solid white;
    margin-bottom: 5px;
  }

  .subtitle {
    color: white;
    display: inline-block;
  }
}
