body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-bg);
}

:root {
  --color-bg: #282828;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.app-body {
  flex: 1;
  background: var(--color-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#legal {
  color: white;
}

#legal ul,
#legal ol,
#legal li {
  margin: revert !important;
  padding: revert !important;
}

#legal h2,
#legal h3 {
  margin-top: revert !important;
}

#credits {
  color: white;
}
