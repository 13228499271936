.login-holder {
  color: #f5f5f5;
  background: #363636;

  margin: 1rem auto 0 auto;
  width: 500px;
  padding: 20px;
}

.login-holder h1 {
  font-size: 2rem;
}

.login-form {
  width: auto;
  margin: auto;
}

.login-holder label {
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
